// $blue: #428bca;
// $purple: #8031a7;
// $primary: $purple;
// $primary-dark: #572C5F;
// $primary-light: #E6D2EB;
$body-color: #4c4c4c;
// $link-hover-color: $primary;
// $gray-100: #f4f4f4;
// $gray-600: #6c757d !default;
// $gray-900: #222222;

// $secondary:     $gray-600 !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900;

// $colors: (
//   "purple":     $purple,
// );
//   // "blue":       $blue,
//   // "indigo":     $indigo,
//   // "pink":       $pink,
//   // "red":        $red,
//   // "orange":     $orange,
//   // "yellow":     $yellow,
//   // "green":      $green,
//   // "teal":       $teal,
//   // "cyan":       $cyan,
//   // "white":      $white,
//   // "gray":       $gray-600,
//   // "gray-dark":  $gray-800
// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "light":      $light,
//   "dark":       $dark
// );
//   // "success":    $success,
//   // "info":       $info,
//   // "warning":    $warning,
//   // "danger":     $danger,

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1212px,
//   xxl: 1213px
// ) !default;

// $font-family-serif: 'Playfair Display', serif;
// $font-family-sans-serif: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
// $headings-font-family: $font-family-serif;
// $headings-line-height: 1;
// $link-decoration: none;
// $link-hover-decoration: underline;
// $font-size-base: 0.875rem;
// $line-height-base: 1.25;
// $min-contrast-ratio: 0.5;
// $btn-border-radius: 0;
// $input-border-radius: 0;
// $input-font-size: 14px;

// $h1-font-size: 28px;
// $h2-font-size: 28px;
// $h3-font-size: 24px;
// $h4-font-size: 22px;
// $h5-font-size: 20px;
// $h6-font-size: 18px;
// $headings-margin-bottom: 1.5rem;

// $fa-font-path: '../webfonts';
