@import 'app/_shared/init.scss';

#content .company-documents-item {
    font-size: 18px;
    &:before {
        display: inline-block;
        content: '';
        width: 21px;
        height: 20px;
        background: url('/im/pdf.png') 50% 50% no-repeat;
    }
    a {
        color: #4E7E9E;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}