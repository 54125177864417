@import 'app/_shared/init.scss';

#content .company-documents-block {
    &__title {
        text-align: center;
        color: #4E7E9E;
        font-weight: bold;
        margin: 2rem 0;
    }
    &__subtitle {
        color: $body-color;
        font-weight: bold;
        margin: 1rem 0 .5rem;
    }
}